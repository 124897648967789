import { useMutationState } from "@tanstack/react-query";

import { GenPredictionQuery } from "@/gql/graphql";
import { cn } from "@/lib/utils";

import { EmptyScreen } from "./empty-screen";
import { useGetPrediction } from "./hooks/use-get-prediction";
import { LabelMessage } from "./label-message";
import { LoadingMessage } from "./loading-message";
import { usePlaygroundStore } from "./playground-store";
import { UserMessage } from "./user-message";

export const PlaygroundMainOutput = () => {
  const { isMutationLoading } = usePlaygroundStore();
  const data = useMutationState({
    // this mutation key needs to match the mutation key of the given mutation (see above)
    filters: { mutationKey: ["genPrediction"] },
    select: (mutation) => mutation.state.data,
  });

  const mutationPredictionData = data.length
    ? (data[data.length - 1] as GenPredictionQuery)
    : null;
  const latestMutationPrediction = mutationPredictionData?.prediction;

  const {
    data: getPredictionData,
    isLoading,
    isFetching,
  } = useGetPrediction({
    predictionId: latestMutationPrediction?.id,
  });
  const prediction = getPredictionData?.getPrediction;

  if (isMutationLoading || isLoading) {
    return (
      <div className="flex-1">
        <LoadingMessage />
      </div>
    );
  } else if (!prediction) {
    return (
      <div className="flex-1">
        <EmptyScreen />
      </div>
    );
  }

  return (
    <div
      className={cn(
        "relative flex flex-1 flex-col gap-4 pb-4",
        isFetching && "animate-pulse",
      )}
    >
      {/* <UserMessage prompt={prediction.prompt} /> */}
      <UserMessage prompt={prediction?.prompt} />
      {prediction.labels.map((label) => (
        <LabelMessage key={label.id} label={label} />
      ))}

      {/* <BotMessage content={testCompletion} /> */}
      {/* <BotMessage content={prediction.completion} /> */}
    </div>
  );
};
