import { useAutoAnimate } from "@formkit/auto-animate/react";
// import { usePostHog } from "posthog-js/react";
import { Outlet } from "react-router-dom";

import { WithSuspendAndErrorBoundary } from "@/components/with-suspend-and-error-boundary";
import { easeSnappy } from "@/lib/motion";
import { cn } from "@/lib/utils";

import { DashboardNav } from "./dashboard-nav";

interface Props extends React.HTMLAttributes<HTMLDivElement> {}

function _DashboardLayout({ className, ...props }: Props) {
  const [parent] = useAutoAnimate({
    duration: 150,
    easing: easeSnappy,
  });
  // const posthog = usePostHog();

  // const { data } = useCurrentUserQuery();
  // const user = data.user;

  // useEffect(() => {
  //   if (user) {
  //     // Identify sends an event, so you want may want to limit how often you call it
  //     posthog?.identify(user.id.toString(), {
  //       email: user.email,
  //       name: `${user.firstName} ${user.lastName}`,
  //     });
  //     posthog?.group("organization", user.organization.id.toString());
  //   }
  // }, [posthog, user]);

  return (
    <div
      ref={parent}
      className={cn(
        "grid h-display w-display grid-cols-[auto_1fr] overflow-hidden",
        className,
      )}
      {...props}
    >
      <DashboardNav />

      <Outlet />
    </div>
  );
}

export const DashboardLayout = WithSuspendAndErrorBoundary(_DashboardLayout);
