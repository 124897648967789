import { DashboardScreen } from "../dashboard-screen";
import { ApiKeyGenerator } from "./api-key-generator";

export function AccountApiPage() {
  return (
    <DashboardScreen scrollable>
      <ApiKeyGenerator />
    </DashboardScreen>
  );
}
