import { PlaygroundMainForm } from "./playground-main-form";
import { PlaygroundMainOutput } from "./playground-main-output";
import { PlaygroundSettings } from "./playground-settings";

export const PlaygroundMain = () => {
  return (
    <main className="grid flex-1 gap-4 overflow-auto p-4 md:grid-cols-2 lg:grid-cols-3">
      <PlaygroundSettings />
      <div className="flex max-h-[90vh] min-h-[50vh] flex-col rounded-xl bg-muted/50 p-4 lg:col-span-2">
        <PlaygroundMainOutput />
        <PlaygroundMainForm />
      </div>
    </main>
  );
};
