import { DashboardScreen } from "../dashboard-screen";
import { PlaygroundHeader } from "./playground-header";
import { PlaygroundMain } from "./playground-main";

export function PlaygroundPage() {
  return (
    <DashboardScreen className="grid p-0">
      <div className="flex flex-col">
        <PlaygroundHeader />
        <PlaygroundMain />
      </div>
    </DashboardScreen>
  );
}
