import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";

import { MobileDrawer } from "./mobile-drawer";

export const PlaygroundHeader = () => {
  return (
    <header className="sticky top-0 z-10 flex h-[57px] items-center gap-1 border-b bg-background px-4">
      <h1 className="text-xl font-semibold">Playground</h1>
      <MobileDrawer />
      <Button variant="outline" size="sm" className="ml-auto gap-1.5 text-sm">
        <Icons.share className="h-4" />
        Share
      </Button>
    </header>
  );
};
