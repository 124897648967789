import { AccountApiPage } from "./account-api-page";
import { AccountGeneralPage } from "./account-general-page";

export const settingsRoutes = [
  {
    id: "General",
    name: "General",
    path: "",
    showInNav: true,
    element: <AccountGeneralPage />,
  },
  {
    id: "API_Key",
    name: "API Key",
    path: "api",
    showInNav: true,
    element: <AccountApiPage />,
  },
];
