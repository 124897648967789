import { graphql } from "@/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

export function useGetModels() {
  return useGraphqlQuery({
    queryKey: ["GetModels"],
    query: graphql(`
      query getModelBackends {
        inferenceBackends {
          promptFormat
          description
          type
          id
          name
          icon
        }
      }
    `),
  });
}
