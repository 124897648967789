import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";

import { Spinner } from "@/components/ui/spinner";
import { useSetJwtCookieAndBearer } from "@/lib/hooks/auth0/auth0";

export const AuthenticationGuard = ({
  component,
}: {
  component: React.ComponentType<any>;
}) => {
  useSetJwtCookieAndBearer();

  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => (
      <div className="flex h-screen w-screen items-center justify-center">
        <Spinner size="xl" />
      </div>
    ),
  });

  return <Component />;
};
