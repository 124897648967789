import { LinkProps, NavLink } from "react-router-dom";

import { Icons } from "@/components/icons";
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
} from "@/components/ui/tooltip";
import { ValueOf } from "@/lib/type-helpers";
import { cn } from "@/lib/utils";

export interface DashboardNavItemProps extends LinkProps {
  name: string;
  isExternal?: boolean;
  Icon: ValueOf<typeof Icons>;
}

export function DashboardNavItem({
  name,
  Icon,
  to,
  isExternal = false,
  className,
  ...props
}: DashboardNavItemProps) {
  return (
    <Tooltip>
      <TooltipTrigger className="w-fit" tabIndex={-1}>
        {isExternal ? (
          <a
            href={to as string}
            target="_blank"
            rel="noopener noreferrer"
            className={cn(
              "text-white hover:bg-background/10 hover:text-foreground/90",
              "inline-flex h-12 w-12 flex-shrink-0 cursor-pointer items-center justify-center rounded-lg transition-colors duration-200 ease-in-out",
              className,
            )}
            {...props}
          >
            <span className="sr-only">{name}</span>
            <Icon className="h-6 w-6" aria-hidden="true" />
          </a>
        ) : (
          <NavLink
            to={to}
            end={to === ""} // for the home page
            className={({ isActive }) =>
              cn(
                isActive
                  ? "bg-background/20 text-white"
                  : "text-white hover:bg-background/10 hover:text-foreground/90",
                "inline-flex h-12 w-12 flex-shrink-0 cursor-pointer items-center justify-center rounded-lg transition-colors duration-200 ease-in-out",
                className,
              )
            }
            {...props}
          >
            <span className="sr-only">{name}</span>
            <Icon className=" h-6 w-6" aria-hidden="true" />
          </NavLink>
        )}
      </TooltipTrigger>
      <TooltipContent side="right" sideOffset={6} align="center">
        {name}
      </TooltipContent>
    </Tooltip>
  );
}
