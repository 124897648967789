// import { XSquareIcon } from "lucide-react";

import { MultiStepLoader as Loader } from "./multi-step-loader";

const loadingStates = [
  {
    text: "Asking mom for money",
  },
  {
    text: "Raising seed round",
  },
  {
    text: "Waking interns up",
  },
  {
    text: "Checking data by hand",
  },
  {
    text: "Building additional pylons",
  },
  {
    text: "Collecting two by fours",
  },
  {
    text: "Waiting for the paint to dry",
  },
  {
    text: "Shipping without testing",
  },
  {
    text: "Regretting that decision",
  },
  {
    text: "Going to the bar",
  },
  {
    text: "Telling the computer to hurry up",
  },
];

type Props = {
  loading: boolean;
};

export function MultiStepLoader({ loading }: Props) {
  return (
    <div className="flex h-[60vh] w-full items-center justify-center">
      {/* Core Loader Modal */}
      <Loader loadingStates={loadingStates} loading={loading} duration={2000} />

      {/* {loading && (
        <button
          className="fixed right-4 top-4 z-[120] text-black dark:text-white"
          //   onClick={() => setLoading(false)}
        >
          <XSquareIcon className="h-10 w-10" />
        </button>
      )} */}
    </div>
  );
}
