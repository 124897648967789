import { useAuth0 } from "@auth0/auth0-react";

import { env } from "@/env";

export function Login() {
  const { loginWithRedirect } = useAuth0();

  loginWithRedirect({
    authorizationParams: {
      audience: env.VITE_PUBLIC_AUTH0_AUDIENCE,
    },
  });

  return <></>;
}
