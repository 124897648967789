import { useMutation } from "@tanstack/react-query";

export type DocumentExtractResponse = {
  data: { [key: string]: any }[];
  usage: {
    input_tokens: number;
    output_tokens: number;
    num_pages: number;
    pre_process: false;
    resolution: string;
    price: number;
  };
};

export function useDocumentExtract() {
  return useMutation({
    mutationKey: ["documentExtract"],
    mutationFn: ({ file, jsonSchema }: { file: File; jsonSchema: string }) => {
      const formData = new FormData();
      formData.append("file", file); // Append the binary file
      formData.append("resolution", "LOW");
      formData.append("pre_process", "false");
      formData.append("json_schema", jsonSchema);

      const url = "https://documents.teachprotege.ai/document-extract";
      return fetch(url, {
        headers: {
          authorization: `Bearer five-dollar-footlong`,
        },
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data: DocumentExtractResponse) => {
          console.log("documentExtract", { data });
          if (Array.isArray(data.data) && data.data.length !== 0) {
            return data.data[0];
          } else {
            return {};
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  });
}
