import { create } from "zustand";

import { Icons } from "@/components/icons";

import { DocumentExtractResponse } from "./use-document-extract-mutation";
import { Field } from "./use-gen-custom-fields";

export const useCases = [
  {
    id: 1,
    type: "invoice",
    title: "Invoice",
    description: "lorem ipsum dolor",
    icon: Icons.documentation,
  },
  {
    id: 2,
    type: "bill-of-lading",
    title: "Bill of Lading",
    description: "lorem ipsum dolor",
    icon: Icons.documentation,
  },
  {
    id: 3,
    type: "receipt",
    title: "Receipt",
    description: "lorem ipsum dolor",
    icon: Icons.documentation,
  },
  {
    id: 4,
    type: "custom",
    title: "Custom",
    description: "lorem ipsum dolor",
    icon: Icons.documentation,
  },
];

type DocumentExtractState = {
  useCases: typeof useCases;
  uploadedFile: File | undefined;
  jsonSchema: any;
  recommendedFields: Field[];
  results: DocumentExtractResponse["data"][0];
};

type DocumentExtractActions = {
  setUseCases: (useCases: DocumentExtractState["useCases"]) => void;
  setUploadedFile: (file: DocumentExtractState["uploadedFile"]) => void;
  setJsonSchema: (jsonSchema: DocumentExtractState["jsonSchema"]) => void;
  setRecommendedFields: (
    fields: DocumentExtractState["recommendedFields"],
  ) => void;
  setResults: (results: DocumentExtractState["results"]) => void;
  getCurrentUseCase: (type?: string) => (typeof useCases)[number] | undefined;
};

export const useDocumentExtractStore = create<
  DocumentExtractState & DocumentExtractActions
>((set, get) => {
  return {
    useCases,
    uploadedFile: undefined,
    jsonSchema: {},
    recommendedFields: [],
    results: {},
    // sets
    setUseCases: (useCases) => set({ useCases }),
    setUploadedFile: (uploadedFile) => set({ uploadedFile }),
    setJsonSchema: (jsonSchema) => set({ jsonSchema }),
    setRecommendedFields: (recommendedFields) => set({ recommendedFields }),
    setResults: (results) => set({ results }),
    // gets
    getCurrentUseCase: (type?: string) => {
      if (!type) return;
      const currentUseCase = get().useCases.find(
        (useCase) => useCase.type === type,
      );
      return currentUseCase;
    },
  };
});
