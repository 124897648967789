import { useState } from "react";
import { toast } from "sonner";

import { Button } from "@/components/ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  invalidateUserQuery,
  useCurrentUserQuery,
} from "@/lib/hooks/queries/user";
import { cn } from "@/lib/utils";

import { useGenApiKey } from "./hooks/use-gen-api-key";

export const ApiKeyGenerator = () => {
  const { data } = useCurrentUserQuery();
  const [showKey, setShowKey] = useState(false);
  const { mutate } = useGenApiKey();

  // hide all letters except the last 4 of the api key
  let apiKey =
    data.user?.apiKey != null
      ? data.user?.apiKey.replace(/./g, "*").slice(0, -4) +
        data.user?.apiKey.slice(-4)
      : "N/A";

  if (showKey && data.user?.apiKey != null) {
    apiKey = data.user?.apiKey;
  }

  const onGenerateNewKey = () => {
    mutate(
      {},
      {
        onSuccess: () => {
          invalidateUserQuery({ id: data.user?.id });
          toast.success("API Key generated successfully");
        },
        onError: () => {
          toast.error("Error generating API Key");
        },
      },
    );
  };

  return (
    <Card className="">
      <CardHeader>
        <CardTitle>API Key</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        <Label className="text-muted-foreground">API Key</Label>
        <div className="flex gap-6 align-middle">
          <Input disabled value={apiKey} />
          <Button variant={"outline"} onClick={() => setShowKey(!showKey)}>
            {showKey ? "Hide" : "Show"}
          </Button>
        </div>
        <Button onClick={onGenerateNewKey}>Generate New</Button>
      </CardContent>
    </Card>
  );
};

export const ApiKeyGeneratorSkeleton = () => {
  return (
    <Card className={cn("max-w-3xl animate-pulse")}>
      <CardHeader>
        <CardTitle>API Key</CardTitle>
      </CardHeader>
      <CardContent className="space-y-2">
        <Label className="text-muted-foreground">API Key</Label>
        <div className="flex gap-6 align-middle">
          <Input disabled value={"**************"} />
          <Button variant={"outline"}>{"Show"}</Button>
        </div>
        <Button>Generate New</Button>
      </CardContent>
    </Card>
  );
};
