import { useState } from "react";
import remarkGfm from "remark-gfm";
import remarkMath from "remark-math";

import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { CodeBlock } from "@/components/ui/codeblock";
import { MemoizedReactMarkdown } from "@/components/ui/markdown";
import { Separator } from "@/components/ui/separator";
import { GetPredictionQuery } from "@/gql/graphql";
import { cn } from "@/lib/utils";

import { EditResponseForm } from "./edit-response-form";

type Props = {
  label: NonNullable<GetPredictionQuery["getPrediction"]>["labels"][0];
  className?: string;
};

export function LabelMessage({ label, className }: Props) {
  const [isEditing, setIsEditing] = useState(false);

  // latest feedback value or the value
  const displayedValue =
    label.feedback.length > 0
      ? label.feedback[label.feedback.length - 1].content
      : label.value;

  return (
    <Card className={cn("relative flex items-center gap-4 p-6", className)}>
      <div className="flex w-full flex-col gap-2">
        <p className="text-lg font-medium">{label.name}</p>
        <Separator />
        {isEditing ? (
          <EditResponseForm setIsEditing={setIsEditing} label={label} />
        ) : (
          <>
            <MemoizedReactMarkdown
              className="prose dark:prose-invert prose-p:leading-relaxed prose-pre:p-0 break-words"
              remarkPlugins={[remarkGfm, remarkMath]}
              components={{
                p({ children }) {
                  return <p className="mb-2 last:mb-0">{children}</p>;
                },
                code({ node, className, children, ...props }) {
                  if (Array.isArray(children) && children.length > 0) {
                    if (children[0] == "▍") {
                      return (
                        <span className="mt-1 animate-pulse cursor-default">
                          ▍
                        </span>
                      );
                    }

                    children[0] = (children[0] as string).replace("`▍`", "▍");
                  }

                  const match = /language-(\w+)/.exec(className || "");

                  if (match == null) {
                    return (
                      <code className={className} {...props}>
                        {children}
                      </code>
                    );
                  }

                  return (
                    <CodeBlock
                      key={Math.random()}
                      language={(match && match[1]) || ""}
                      value={String(children).replace(/\n$/, "")}
                      {...props}
                    />
                  );
                },
              }}
            >
              {displayedValue}
            </MemoizedReactMarkdown>
            <Card className="absolute -bottom-5 right-4 flex gap-1 p-1 text-primary/70">
              <Button
                onClick={() => setIsEditing(true)}
                size={"xs"}
                variant={"ghost"}
                className="space-x-1"
              >
                <Icons.edit className="h-3 w-3" />
                <p>edit</p>
              </Button>
              <Button size={"xs"} variant={"ghost"} className="space-x-1">
                <Icons.copy className="h-3 w-3" />
                <p>copy</p>
              </Button>
              <Button size={"xs"} variant={"ghost"} className="space-x-1">
                <Icons.upVote className="h-3 w-3" />
              </Button>
              <Button size={"xs"} variant={"ghost"} className="space-x-1">
                <Icons.downVote className="h-3 w-3" />
              </Button>
            </Card>
          </>
        )}
      </div>
    </Card>
  );
}
