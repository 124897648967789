import { outdent } from "outdent";

import { PromptFormat } from "@/gql/graphql";

type Props = {
  format: PromptFormat;
  system: string;
  prompt: string;
};

export function formatPrompt({ format, system, prompt }: Props) {
  if (format === PromptFormat.Basic) {
    return formatBasicPrompt(system, prompt);
  } else if (format === PromptFormat.Chatml) {
    return formatChatMLPrompt(system, prompt);
  } else {
    throw new Error(`Invalid prompt format: ${format}`);
  }
}

export function formatChatMLPrompt(system: string, prompt: string) {
  return outdent`
    <|im_start|>system
    ${system}
    <|im_end|>
    <|im_start|>user
    ${prompt}
    <|im_end|>
    <|im_start|>assistant`;
}

export function formatBasicPrompt(system: string, prompt: string) {
  return outdent`
    Instructions: 
    ${system}

    ${prompt}`;
}

export function formatInstructPrompt(system: string, prompt: string) {
  return outdent`
  <s>[INST] Instructions:\n${system}\n${prompt} [/INST]`;
}
