import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export function useUpdateUser() {
  return useGraphqlMutation({
    mutationKey: ["UpdateUser"],
    mutation: graphql(`
      mutation UpdateUser(
        $data: UserUpdateInput!
        $where: UserWhereUniqueInput!
      ) {
        updateOneUser(data: $data, where: $where) {
          name
          id
          email
        }
      }
    `),
  });
}
