import React from "react";
import ReactDOM from "react-dom/client";

import "./globals.css";

import { QueryClientProvider } from "@tanstack/react-query";
import { RouterProvider } from "react-router-dom";

import { ThemeProvider } from "@/components/theme-provider";

import { TailwindIndicator } from "./components/tailwind-indicator.tsx";
import { Toaster } from "./components/ui/toaster";
import { TooltipProvider } from "./components/ui/tooltip.tsx";
import { AuthProvider } from "./providers/auth-provider.tsx";
import { queryClient } from "./queryClient.ts";
import { router } from "./router.tsx";

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <AuthProvider>
      <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
        <QueryClientProvider client={queryClient}>
          <TailwindIndicator />
          <TooltipProvider delayDuration={200}>
            <Toaster position="top-right" />

            <RouterProvider router={router} />
          </TooltipProvider>
        </QueryClientProvider>
      </ThemeProvider>
    </AuthProvider>
  </React.StrictMode>,
);
