import { z } from "zod";

import { Field } from "./use-gen-custom-fields";

// Function to generate Zod schema from fields
export function generateZodSchemaFromFields(fields: Field[]): z.ZodTypeAny {
  const schemaProperties: Record<string, z.ZodTypeAny> = {};

  fields.forEach((field) => {
    let zodSchema: z.ZodTypeAny;
    switch (field.type) {
      case "text":
        zodSchema = z.string();
        break;
      case "number":
        zodSchema = z.number();
        break;
      case "date":
        zodSchema = z.string();
        break;
      case "address":
        zodSchema = z.string(); // Simplified; consider a more specific pattern if needed
        break;
      default:
        zodSchema = z.unknown();
    }
    zodSchema = zodSchema.nullable();

    schemaProperties[field.name] = zodSchema.describe(field.description);
  });

  return z.object(schemaProperties);
}
