import { useMutation } from "@tanstack/react-query";

export type Field = {
  name: string;
  type: string;
  description: string;
  required: boolean;
};

export type GenCustomFieldsResponse = {
  fields: Field[];
  usage: {
    input_tokens: number;
    output_tokens: number;
    num_pages: number;
    pre_process: false;
    resolution: string;
    price: number;
  };
};

export function useGenCustomFields() {
  return useMutation({
    mutationKey: ["genCustomFields"],
    mutationFn: (file: File) => {
      const formData = new FormData();
      formData.append("file", file); // Append the binary file
      formData.append("resolution", "LOW");
      formData.append("pre_process", "false");

      const url = "https://documents.teachprotege.ai/infer-schema";
      return fetch(url, {
        headers: {
          authorization: `Bearer five-dollar-footlong`,
        },
        method: "POST",
        body: formData,
      })
        .then((response) => response.json())
        .then((data: GenCustomFieldsResponse) => {
          console.log("genCustomFields", { data });
          // if fields are asd_Asd split them into asd Asd
          return data;
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    },
  });
}
