import { useEffect } from "react";
import { zodResolver } from "@hookform/resolvers/zod";
import { Controller, FieldValues, useForm } from "react-hook-form";
import { toast } from "sonner";
import { z } from "zod";

import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { Form } from "@/components/ui/form";
import { Label } from "@/components/ui/label";
import { Textarea } from "@/components/ui/textarea";
import { formatPrompt } from "@/lib/format-prompt";

import { useGenPrediction } from "./hooks/use-gen-prediction";
import { usePlaygroundStore } from "./playground-store";

const messageSchema = z.object({
  message: z.string().min(1, "Message is required"),
  temperature: z
    .number()
    .min(0, "Temperature must be greater than 0")
    .max(1, "Temperature must be less than 1"),
  selectedModel: z.string().min(1, "Model is required"),
});

export const PlaygroundMainForm = () => {
  const {
    selectedModel,
    temperature,
    topK,
    topP,
    setIsMutationLoading,
    content,
  } = usePlaygroundStore();
  const form = useForm({
    resolver: zodResolver(messageSchema),
    defaultValues: {
      message: "",
      temperature,
      selectedModel: selectedModel?.name ?? "",
      topK,
      topP,
    },
  });
  const { mutate } = useGenPrediction();

  useEffect(() => {
    form.reset({
      message: form.getValues("message"),
      temperature,
      selectedModel: selectedModel?.name ?? "",
    });
  }, [selectedModel, temperature]);

  function onSubmit(values: FieldValues) {
    setIsMutationLoading(true);
    const { message } = values as z.infer<typeof messageSchema>;
    // Handle form submission here and reset the form
    if (selectedModel != null) {
      mutate(
        {
          openaiKwargs: {
            max_tokens: 250,
            temperature,
            top_p: topP,
          },
          prompt: formatPrompt({
            prompt: message,
            format: selectedModel.promptFormat,
            system: content,
          }),
          modelBackendName: selectedModel.name,
        },
        {
          onSuccess: () => {
            setIsMutationLoading(false);
            toast.success("Message sent successfully");
            form.reset();
          },
          onError: () => {
            setIsMutationLoading(false);
            toast.error("Failed to send message");
          },
        },
      );
    } else {
      setIsMutationLoading(false);
      toast.error("Model is required");
    }
  }

  const isErrors = Object.keys(form.formState.errors).length > 0;

  return (
    <Form {...form}>
      <form
        onSubmit={form.handleSubmit((e) => onSubmit(e))}
        className="relative overflow-hidden rounded-lg border bg-background focus-within:ring-1 focus-within:ring-ring"
        x-chunk="dashboard-03-chunk-1"
      >
        <Label htmlFor="message" className="sr-only">
          Message
        </Label>
        <Controller
          name="message"
          control={form.control}
          defaultValue=""
          render={({ field }) => (
            <Textarea
              {...field}
              id="message"
              placeholder="Type your message here..."
              className={`min-h-12 resize-none border-0 p-3 shadow-none focus-visible:ring-0 ${
                isErrors ? "border-red-500" : ""
              }`}
            />
          )}
        />

        <div className="flex items-center p-3 pt-0">
          {/* <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="icon">
              <Icons.paperclip className="h-5" />
              <span className="sr-only">Attach file</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top">Attach File</TooltipContent>
        </Tooltip> */}
          {/* <Tooltip>
          <TooltipTrigger asChild>
            <Button variant="ghost" size="icon">
              <Icons.mic className="h-5" />
              <span className="sr-only">Use Microphone</span>
            </Button>
          </TooltipTrigger>
          <TooltipContent side="top">Use Microphone</TooltipContent>
        </Tooltip> */}
          <Button type="submit" size="sm" className="ml-auto gap-1.5">
            Send Message
            <Icons.cornerDownLeft className="h-5" />
          </Button>
        </div>
      </form>
      {isErrors && (
        <div className="flex flex-col gap-1 pt-1">
          {Object.entries(form.formState.errors).map(([key, value]) => (
            <p key={key} className="text-xs text-red-500">
              {`- ${value.message}`}
            </p>
          ))}
        </div>
      )}
    </Form>
  );
};
