import { Card } from "@/components/ui/card";

export function UserMessage({ prompt }: { prompt: string }) {
  return (
    <Card className="flex items-center gap-4 p-4">
      <div className="relative h-8 w-8 rounded-full bg-red-500">
        <span className="absolute inset-0 flex items-center justify-center text-sm font-bold text-primary-foreground">
          {"BR"}
        </span>
      </div>

      <p className=" flex-1 space-y-2 overflow-hidden">{prompt}</p>
    </Card>
  );
}
