import { Card } from "@/components/ui/card";
import { Spinner } from "@/components/ui/spinner";

export function LoadingMessage() {
  return (
    <Card className="flex items-center gap-4 p-4">
      <Spinner></Spinner>
    </Card>
  );
}
