import React, { useRef, useState } from "react";
import { useDropArea } from "react-use";

import { Icons } from "@/components/icons";
import { TextShimmer } from "@/components/motion/text-shimmer";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Spinner } from "@/components/ui/spinner";
import { cn } from "@/lib/utils";

type Props = {
  setFileToUpload?: React.Dispatch<React.SetStateAction<File | undefined>>;
  onUpload?: (file: File) => void;
  isUploading?: boolean;
  file?: File;
};

export const FileUpload = ({
  setFileToUpload,
  onUpload,
  isUploading,
  file,
}: Props) => {
  const [error, setError] = useState<string>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  //   only accept csv and excel files
  const fileTypeChecker = (file: File) => {
    const acceptedFileTypes = [
      // "text/csv",
      // "application/vnd.ms-excel",
      // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/pdf", // MIME type for PDF files
      "image/png", // MIME type for PNG files
      "image/jpeg", // MIME type for JPEG files
    ];
    const isFileValid = acceptedFileTypes.includes(file?.type);
    if (!isFileValid) {
      setError("File type not supported. Please use a supported file.");
      //   clear the error after 5 seconds
      setTimeout(() => {
        setError(undefined);
      }, 5000);
    }
    return isFileValid;
  };

  const uploadActions = (file: File) => {
    if (file && fileTypeChecker(file)) {
      setFileToUpload && setFileToUpload(file);
      onUpload && onUpload(file);
    }
  };

  const [bond, state] = useDropArea({
    onFiles: (files) => {
      if (fileTypeChecker(files[0])) {
        uploadActions(files[0]);
      }
    },
    onUri: (uri) => console.log("uri", uri),
    onText: (text) => console.log("text", text),
  });

  const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    const file = files?.[0];
    if (file && fileTypeChecker(file)) {
      uploadActions(file);
    }
  };

  const onDropTargetClick = () => {
    if (isUploading) return;
    fileInputRef?.current?.click(); // Open file explorer
  };

  return (
    <Card
      {...bond}
      aria-disabled={isUploading}
      className={cn(
        "@container group flex h-full w-full cursor-pointer flex-col items-center justify-center gap-1 border-2 border-dashed border-foreground/40 transition-colors hover:border-foreground/70 hover:bg-muted/40",
        state.over ? "bg-muted/40" : "bg-muted/30",
        isUploading && "animate-pulse cursor-auto bg-card opacity-50",
      )}
      onClick={onDropTargetClick}
    >
      {isUploading ? (
        <>
          <Spinner size="2xl" />
          <p>Uploading {file?.name}</p>
        </>
      ) : (
        <div className="flex flex-col items-center gap-6">
          <Icons.upload
            className={cn(
              "h-12 w-12 text-muted-foreground transition-colors group-hover:text-foreground",
              state.over && "text-foreground",
            )}
          />
          <div className="flex flex-col items-center gap-3">
            <TextShimmer className="select-none pt-2 text-center text-xl font-semibold">
              Drop to Upload File, or Click to Browse
            </TextShimmer>

            <p className="text-xs text-muted-foreground">
              {[`PDF`, `PNG`, `JPEG`].map((ext, _i) => (
                <span
                  key={ext}
                  className={cn(
                    "mr-1.5 select-none text-xs font-semibold text-muted-foreground",
                  )}
                >
                  {ext}
                </span>
              ))}
              supported
            </p>
          </div>
          <Button size={"lg"} className="@xs:w-64 w-48">
            Choose File
          </Button>
          {error && <span className="text-destructive">{error}</span>}
          <input
            type="file"
            ref={fileInputRef}
            onChange={onFileInputChange}
            style={{ display: "none" }} // Hide the file input
          />
        </div>
      )}
    </Card>
  );
};
