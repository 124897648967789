import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export function useSetFeedback() {
  return useGraphqlMutation({
    mutationKey: ["setFeedback"],
    mutation: graphql(`
      mutation Mutation($data: FeedbackCreateInput!) {
        createOneFeedback(data: $data) {
          id
          content
        }
      }
    `),
  });
}
