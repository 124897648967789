import { useState } from "react";

import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
} from "@/components/ui/dialog";
import { Input } from "@/components/ui/input";

import { useDocumentExtractStore } from "./document-extract-store";

export const EmailResultsDialog = () => {
  const { results } = useDocumentExtractStore();
  const [email, setEmail] = useState<string>("");
  const onEmailResults = () => {
    // send email to input email address
    console.log({ results, email });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="mt-6">Email Results To Me</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader className="text-3xl font-bold tracking-tight sm:text-6xl">
          Send Results
        </DialogHeader>
        {/* <Input placeholder="Email Address" type="email" /> */}
        <Input
          placeholder="Email Address"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        />
        <DialogFooter>
          <Button onClick={onEmailResults}>Send</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
