import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";

type Props = {
  label: string;
  value: any;
};

export const ExtractionField = ({ label, value }: Props) => {
  if (Array.isArray(value)) {
    return (
      <div className="col-span-2">
        <Label>
          {label} ({value.length})
        </Label>
        <div className=" flex flex-wrap gap-2">
          {value.map((val, i) => (
            <Input key={i} disabled value={val} />
          ))}
        </div>
      </div>
    );
  }
  return (
    <div>
      <Label>{label}</Label>
      <Input disabled value={value} />
    </div>
  );
};
