import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { zodToJsonSchema } from "zod-to-json-schema";

import { Button } from "@/components/ui/button";

import { useDocumentExtractStore } from "./document-extract-store";
import { generateZodSchemaFromFields } from "./gen-zod-schema-from-fields";
import { InputField } from "./input-field";
import { MultiStepLoader } from "./loading-state";
import { useDocumentExtract } from "./use-document-extract-mutation";
import { Field } from "./use-gen-custom-fields";

export const CreateCustomSchemaPage = () => {
  const navigate = useNavigate();
  const { recommendedFields, setResults, uploadedFile } =
    useDocumentExtractStore();
  const {
    mutate: documentExtract,
    isPending,
    isError,
    error,
  } = useDocumentExtract();
  const aiGenFields = recommendedFields.slice(0, 5);

  const [fields, setFields] = useState<Field[]>(aiGenFields);
  const [name, setName] = useState<string>("");
  const [type, setType] = useState<string>("text");
  const [description, setDescription] = useState<string>("");

  if (!uploadedFile) {
    navigate("/document-extract");
  }

  const addField = () => {
    setFields((prevFields) => {
      return [
        ...prevFields,
        {
          name,
          type,
          description,
          required: false,
        },
      ];
    });
    setName("");
    setType("text");
    setDescription("");
  };

  const extract = () => {
    if (!uploadedFile) return;
    const zodSchema = generateZodSchemaFromFields(fields);
    // create JSON Schema from fields
    const test = zodToJsonSchema(zodSchema);
    const jsonSchema = JSON.stringify(zodToJsonSchema(zodSchema));
    // send to document extract
    console.log({ jsonSchema, zodSchema, test });
    documentExtract(
      { file: uploadedFile, jsonSchema },
      {
        onSuccess: (response) => {
          console.log("documentExtract response:", { response });
          // set results
          if (response) {
            setResults(response);
            navigate("../results");
          }
        },
        onError: (error) => {
          console.error("Error:", error);
        },
      },
    );
  };

  if (isPending) {
    return <MultiStepLoader loading={isPending} />;
  } else if (isError && error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="mx-auto max-w-7xl space-y-8 px-6 lg:px-8">
      <section className="mx-auto max-w-2xl text-center">
        <h1 className="text-4xl font-bold tracking-tight sm:text-6xl">
          Step 2: Define Custom Fields
        </h1>
        <p className="mt-6 text-lg leading-8 text-primary/60">
          Let us know what information you want to extract from your document.
        </p>
      </section>
      <section className="space-y-4">
        {fields.map((field, index) => (
          <InputField
            name={field.name}
            type={field.type}
            description={field.description}
            key={index}
            onNameChange={(name) => {
              setFields((prevFields) => {
                prevFields[index].name = name;
                return [...prevFields];
              });
            }}
            onTypeChange={(type) => {
              setFields((prevFields) => {
                prevFields[index].type = type;
                return [...prevFields];
              });
            }}
            onDescriptionChange={(description) => {
              setFields((prevFields) => {
                prevFields[index].description = description;
                return [...prevFields];
              });
            }}
            onRemove={() => {
              setFields((prevFields) => {
                prevFields.splice(index, 1);
                return [...prevFields];
              });
            }}
            index={index}
          />
        ))}
        <InputField
          name={name}
          type={type}
          description={description}
          onNameChange={setName}
          onTypeChange={setType}
          onDescriptionChange={setDescription}
        />
      </section>
      <section className="flex gap-4">
        <Button onClick={addField} className="w-full">
          Add Field
        </Button>
        <Button onClick={extract} className="w-full">
          Extract!
        </Button>
      </section>
    </div>
  );
};
