import { FieldValues, UseFormReturn } from "react-hook-form";

import { Button } from "@/components/ui/button";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import { Form } from "@/components/ui/form";
import { Separator } from "@/components/ui/separator";
import { cn } from "@/lib/utils";

interface SettingsFormProps<T extends FieldValues>
  extends React.ComponentProps<typeof Card> {
  form: UseFormReturn<T>;
  onFormSubmit: (values: T) => void;
  isPending: boolean;
  title?: string;
  description?: string;
}

export const SettingsForm = <T extends FieldValues>({
  form,
  onFormSubmit,
  isPending,
  children,
  title,
  description,
  ...props
}: SettingsFormProps<T>) => {
  const includeHeader = !!(title || description);

  return (
    <Card {...props}>
      {includeHeader && (
        <CardHeader>
          {title && <CardTitle>{title}</CardTitle>}
          {description && <CardDescription>{description}</CardDescription>}
        </CardHeader>
      )}
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onFormSubmit)}>
          <CardContent
            className={cn("flex flex-col gap-4", !includeHeader && "pt-6")}
          >
            {children}
          </CardContent>
          <Separator className="mb-7" />
          <CardFooter className="flex justify-end gap-3">
            <Button
              variant="ghost"
              onClick={() => {
                form.reset();
              }}
              disabled={!form.formState.isDirty}
            >
              Discard Changes
            </Button>
            <Button
              type="submit"
              isLoading={isPending}
              className="w-32"
              disabled={!form.formState.isDirty}
            >
              Save
            </Button>
          </CardFooter>
        </form>
      </Form>
    </Card>
  );
};
