import React from "react";
import { ErrorBoundary } from "react-error-boundary";

import { Spinner } from "./ui/spinner";

// TODO: Implement better fallbacks here and maybe allow for passing in skeleton components

// a higher order component for wrapping any component with an error boundary and suspense
export function WithSuspendAndErrorBoundary<T extends React.ComponentType<any>>(
  WrappedComponent: T,
): React.FC<React.ComponentPropsWithRef<T>> {
  return function (props) {
    return (
      <ErrorBoundary fallback={<div>Something went wrong.</div>}>
        <React.Suspense fallback={<Spinner size="2xl" />}>
          <WrappedComponent {...props} />
        </React.Suspense>
      </ErrorBoundary>
    );
  };
}
