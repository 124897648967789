import { useCases } from "./document-extract-store";
import { UseCaseTile } from "./use-case-tiles";

export function DocumentExtractPage() {
  return (
    <>
      <div className="mx-auto max-w-2xl text-center">
        <h1 className="text-4xl font-bold tracking-tight sm:text-6xl">
          Parse Any Document
        </h1>
        <p className="mt-6 text-lg leading-8 text-primary/60">
          Perfect for any workflow, if you can read it, we can parse it! Get
          started with our template options.
        </p>
      </div>
      <div className="mt-16 grid grid-cols-2 grid-rows-2 gap-4 sm:mt-24">
        {useCases.map((useCase, index) => (
          <UseCaseTile key={index} {...useCase} />
        ))}
      </div>
    </>
  );
}
