import { create } from "zustand";

import { GetModelBackendsQuery } from "@/gql/graphql";

type PlaygroundState = {
  selectedModel: GetModelBackendsQuery["inferenceBackends"][0] | undefined;
  temperature: number;
  role: "system" | "user" | "assistant";
  content: string;
  // prediction: GenPredictionQuery["prediction"] | undefined;
  topP: number;
  topK: number;
  isMutationLoading: boolean;
  // predictionId: number | undefined;
};

type PlaygroundActions = {
  setSelectedModel: (selectedModel: PlaygroundState["selectedModel"]) => void;
  setTemperature: (temperature: PlaygroundState["temperature"]) => void;
  setRole: (role: PlaygroundState["role"]) => void;
  setContent: (content: PlaygroundState["content"]) => void;
  // setPrediction: (prediction: PlaygroundState["prediction"]) => void;
  setTopP: (topP: PlaygroundState["topP"]) => void;
  setTopK: (topK: PlaygroundState["topK"]) => void;
  setIsMutationLoading: (
    isLoading: PlaygroundState["isMutationLoading"],
  ) => void;
  // setPredictionId: (predictionId: PlaygroundState["predictionId"]) => void;
};

export const usePlaygroundStore = create<PlaygroundState & PlaygroundActions>(
  (set, _get) => {
    return {
      selectedModel: undefined,
      temperature: 1,
      role: "system",
      content: "",
      // prediction: undefined,
      topP: 0.7,
      topK: 0.0,
      isMutationLoading: false,
      // predictionId: undefined,
      setSelectedModel: (selectedModel) => set({ selectedModel }),
      setTemperature: (temperature) => set({ temperature }),
      setRole: (role) => set({ role }),
      setContent: (content) => set({ content }),
      // setPrediction: (prediction) => set({ prediction }),
      setTopP: (topP) => set({ topP }),
      setTopK: (topK) => set({ topK }),
      setIsMutationLoading: (isMutationLoading) => set({ isMutationLoading }),
      // setPredictionId: (predictionId) => set({ predictionId }),
    };
  },
);
