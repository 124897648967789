// import { useCurrentUserQuery } from "@/lib/hooks/queries/User";

import { useCurrentUserQuery } from "@/lib/hooks/queries/user";

import { DashboardScreen } from "../dashboard-screen";
import {
  UserInformationForm,
  UserInformationFormSkeleton,
} from "./user-information-form";

export function AccountGeneralPage() {
  const { data, isLoading } = useCurrentUserQuery();
  const user = data?.user;

  return (
    <DashboardScreen scrollable>
      {!isLoading && user != null ? (
        <UserInformationForm user={user} />
      ) : (
        <UserInformationFormSkeleton />
      )}
    </DashboardScreen>
  );
}
