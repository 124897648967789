import React from "react";
import { Link } from "react-router-dom";

import { Card } from "@/components/ui/card"; // Ensure your Card component is appropriately imported

type Props = {
  title: string;
  description: string;
  icon: React.FC<React.SVGProps<SVGSVGElement>>;
  type: string;
};

export const UseCaseTile = ({
  title,
  description,
  icon: Icon,
  type,
}: Props) => {
  // const fileInputRef = useRef<HTMLInputElement>(null);
  // const onFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   const files = event.target.files;
  //   const file = files?.[0];

  //   if (file) {
  //     // check file type only accept pdf,png,jpg
  //     const acceptedFileTypes = ["application/pdf", "image/png", "image/jpeg"];
  //     const isFileValid = acceptedFileTypes.includes(file?.type);

  //     if (!isFileValid) {
  //       toast.error(
  //         "File type not supported. Please use PDF, PNG or JPEG files.",
  //       );
  //     }

  //     console.log(`File selected: ${file.name}`, { isFileValid });
  //     onUpload(file);

  //     //   setFileToUpload(file);  // Function to handle file upload logic
  //   }
  // };

  return (
    <Link to={`./upload?type=${type}`}>
      <Card
        className="flex cursor-pointer flex-col items-center justify-center p-4 hover:bg-gray-300"
        // onClick={() => fileInputRef?.current?.click()}
      >
        <Icon className="h-10 w-10 text-emerald-400" />
        <h2 className="text-md mt-4 text-center font-semibold">{title}</h2>
        <p className="text-center text-primary/50">{description}</p>
        {/* <input
        type="file"
        ref={fileInputRef}
        onChange={onFileInputChange}
        style={{ display: "none" }}
        accept="image/*, application/pdf"
      /> */}
      </Card>
    </Link>
  );
};
