import { graphql } from "@/gql";
import { useGraphqlQuery } from "@/lib/hooks/graphql";

type Props = {
  predictionId?: number;
};

export function useGetPrediction({ predictionId }: Props) {
  return useGraphqlQuery({
    queryKey: ["GetPrediction", predictionId],
    query: graphql(`
      query GetPrediction($where: PredictionWhereUniqueInput!) {
        getPrediction(where: $where) {
          id
          completion
          prompt
          response
          labels {
            confidence
            id
            name
            value
            feedback {
              createdAt
              content
              id
              user {
                name
                email
              }
              upvote
            }
            createdAt
          }
          model {
            name
          }
        }
      }
    `),
    variables: {
      where: {
        id: predictionId,
      },
    },
    enabled: predictionId != null,
  });
}
