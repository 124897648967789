import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export function useGenApiKey() {
  return useGraphqlMutation({
    mutationKey: ["GenApiKey"],
    mutation: graphql(`
      query GenApiKey {
        generateApiKey
      }
    `),
  });
}
