import { useEffect, useRef, useState } from "react";

import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";
import { GetPredictionQuery } from "@/gql/graphql";
import { useEnterSubmit } from "@/lib/hooks/use-enter-submit";
import { cn } from "@/lib/utils";
import { queryClient } from "@/queryClient";

import { useSetFeedback } from "./hooks/use-set-feedback";

type Props = {
  label: NonNullable<GetPredictionQuery["getPrediction"]>["labels"][0];
  setIsEditing: (value: boolean) => void;
};

export const EditResponseForm = ({ label, setIsEditing }: Props) => {
  const { formRef, onKeyDown } = useEnterSubmit();
  const inputRef = useRef<HTMLTextAreaElement>(null);

  // latest feedback value or the value
  const displayedValue =
    label.feedback.length > 0
      ? label.feedback[label.feedback.length - 1].content
      : label.value;
  const [input, setInput] = useState(displayedValue ?? "");

  const { mutate, isPending } = useSetFeedback();

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // Effect hook to listen for clicks outside the edit form
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (formRef.current && !formRef.current.contains(event.target as Node)) {
        setIsEditing(false);
      }
    };

    // Adding click listener
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup function
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [formRef]);

  return (
    <form
      ref={formRef}
      onSubmit={(e) => {
        e.preventDefault();

        const value = input.trim();
        if (!value) return;

        mutate(
          {
            data: {
              label: {
                connect: {
                  id: label.id,
                },
              },
              upvote: true,
              content: value,
            },
          },
          {
            onSuccess: () => {
              setIsEditing(false);
              queryClient.invalidateQueries({ queryKey: ["GetPrediction"] });
            },
          },
        );
      }}
    >
      <Textarea
        ref={inputRef}
        tabIndex={0}
        onKeyDown={onKeyDown}
        className={cn(
          "min-h-[60px] w-full resize-none rounded-md border-border bg-transparent px-4 py-[1.3rem] focus-within:outline-none sm:text-sm",
          isPending && "animate-pulse cursor-not-allowed",
        )}
        autoFocus
        spellCheck={false}
        autoComplete="off"
        autoCorrect="off"
        name="message"
        // rows={4}
        value={input}
        onChange={(e) => setInput(e.target.value)}
      />
      <Card className="absolute -bottom-5 right-4 flex gap-1 p-1 text-primary/70">
        <Button size={"xs"} variant={"ghost"} className="space-x-1">
          <Icons.send className="h-4 w-4" />
          <p>submit feedback</p>
        </Button>
      </Card>
    </form>
  );
};
