import { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { useDocumentExtractStore } from "./document-extract-store";
import { FileUpload } from "./file-upload";
import { MultiStepLoader } from "./loading-state";
import { useGenCustomFields } from "./use-gen-custom-fields";

export const UploadPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get("type");
  const { setUploadedFile, getCurrentUseCase, setRecommendedFields } =
    useDocumentExtractStore();
  const currentUseCase = getCurrentUseCase(type ?? undefined);

  const {
    mutate: genCustomFields,
    isPending,
    isError,
    error,
  } = useGenCustomFields();
  const [fileToUpload, setFileToUpload] = useState<File>();

  // if there is no type redirect to home
  if (type == null || currentUseCase == null) {
    navigate("/document-extract");
  }

  const onUpload = (file: File) => {
    setUploadedFile(file);
    // on upload if it is not custom I want to send it directly to document extract with the json schema
    // on upload redirect to your extractions page before document extract finishes
    if (type !== "custom") {
      // send to document extract
      navigate(`../your-extractions?type=${type}`);
    }

    // if it is custom I want to send it to GPT4 to get suggested fields for the json schema
    // before the response from GPT redirect to the create custom schema page
    else {
      // call GPT4
      // need to handle errors
      genCustomFields(file, {
        onSuccess: (response) => {
          console.log("genCustomFields response:", { response });
          if (!response) return;
          const { fields } = response;
          setRecommendedFields(fields);
          navigate("../custom?type=${type}");
        },
        onError: (error) => {
          console.error("Error:", error);
        },
      });
    }
  };

  if (isPending) {
    return <MultiStepLoader loading={isPending} />;
  } else if (isError && error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div className="">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto max-w-2xl text-center">
          <h1 className="text-red text-4xl font-bold tracking-tight sm:text-6xl">
            Step 1: Upload
          </h1>
          <p className="mt-6 text-lg leading-8 text-primary/60">
            {`Choose the document you want to extract `}
            <span className=" font-semibold underline">
              {currentUseCase?.title}
            </span>
            {` information from.`}
          </p>
          <div>
            <FileUpload
              setFileToUpload={setFileToUpload}
              onUpload={(file) => {
                onUpload(file);
                console.log("File uploaded");
              }}
              isUploading={false}
              file={fileToUpload}
            />
          </div>
          <p className="pt-2 text-xs text-primary/60">
            FYI: Extraction may take up to 30 seconds depending on the
            complexity of the file. If you chose "Custom" we will analyze the
            document and suggest fields for you to extract.
          </p>
        </div>
      </div>
    </div>
  );
};
