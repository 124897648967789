import { createEnv } from "@t3-oss/env-core";
import { z } from "zod";

export const env = createEnv({
  /**
   * Specify what prefix the client-side variables must have.
   * This is enforced both on type-level and at runtime.
   */
  clientPrefix: "VITE_PUBLIC_",
  client: {
    // VITE_PUBLIC_APOLLO_API_URL: z.string().min(1).url(),
    VITE_PUBLIC_PROTEGE_ENGINE_API_URL: z.string().min(1).url(),
    VITE_PUBLIC_PROTEGE_ENGINE_API_KEY: z.string().min(1),
    // VITE_PUBLIC_SENTRY_DSN: z.string().min(1).url(),
    // VITE_PUBLIC_SEGMENT_WRITE_KEY: z.string().min(1),
    VITE_PUBLIC_AUTH0_DOMAIN: z.string().min(1),
    VITE_PUBLIC_AUTH0_CLIENT_ID: z.string().min(1),
    VITE_PUBLIC_AUTH0_AUDIENCE: z.string().min(1).url(),
    // VITE_PUBLIC_NODE_ENV: z.enum(["development", "production", "preview"]),
    // VITE_PUBLIC_POSTHOG_KEY: z.string().startsWith("phc_"),
    // VITE_PUBLIC_POSTHOG_HOST: z.string().min(1).url(),
  },
  /**
   * Specify the private build variables here.
   */
  server: {},
  /**
   * What object holds the environment variables at runtime.
   * Often `process.env` or `import.meta.env`
   */
  runtimeEnv: import.meta.env,
});
