import { NavLink } from "react-router-dom";

import { Icons } from "@/components/icons";
import { cn } from "@/lib/utils";

import { settingsRoutes } from "./settings-routes";

export function SettingsNav() {
  return (
    <nav className="flex w-56 flex-col gap-6 px-6 py-6">
      <div className="flex flex-col text-sm ">
        <h2 className="mb-2 flex gap-2 uppercase text-muted-foreground">
          <Icons.user className="h-5 w-5" />
          Account
        </h2>
        {settingsRoutes.map(
          (route) =>
            route.showInNav && (
              <NavLink
                key={`settings-link-${route.id}`}
                to={`${route.path}`}
                end={route.path === ""}
                className={({ isActive }) =>
                  cn(
                    isActive
                      ? " text-foreground"
                      : "text-muted-foreground hover:text-foreground/90",
                    "ml-7 inline-flex h-10 flex-shrink-0 cursor-pointer items-center rounded-lg transition-all duration-200 ease-in-out",
                  )
                }
              >
                {route.name}
              </NavLink>
            ),
        )}
      </div>
    </nav>
  );
}
