import { graphql } from "@/gql";
import { useGraphqlMutation } from "@/lib/hooks/graphql";

export function useGenPrediction() {
  return useGraphqlMutation({
    mutationKey: ["genPrediction"],
    mutation: graphql(`
      query genPrediction(
        $openaiKwargs: OpenAIKwargs!
        $prompt: String!
        $modelBackendName: String!
      ) {
        prediction(
          openaiKwargs: $openaiKwargs
          prompt: $prompt
          modelBackendName: $modelBackendName
        ) {
          id
          completion
          prompt
          labels {
            id
            name
            value
          }
          metadata {
            key
            value
          }
        }
      }
    `),
  });
}
