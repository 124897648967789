import {
  ArrowDown,
  ArrowUpRight,
  Book,
  Bot,
  Brain,
  Check,
  ChevronRight,
  Code2,
  Copy,
  CornerDownLeft,
  Download,
  Edit,
  Home,
  Info,
  LineChart,
  LogOut,
  Mic,
  Paperclip,
  PlayCircle,
  Plus,
  Send,
  Settings,
  Share,
  Terminal,
  ThumbsDown,
  ThumbsUp,
  Upload,
  User,
  XCircleIcon,
} from "lucide-react";

const Register = {
  analytics: LineChart,
  arrowDown: ArrowDown,
  arrowUpRight: ArrowUpRight,
  ai: Brain,
  api: Code2,
  bot: Bot,
  user: User,
  check: Check,
  chevronRight: ChevronRight,
  cornerDownLeft: CornerDownLeft,
  copy: Copy,
  delete: XCircleIcon,
  documentation: Book,
  download: Download,
  downVote: ThumbsDown,
  edit: Edit,
  home: Home,
  info: Info,
  logout: LogOut,
  mic: Mic,
  settings: Settings,
  send: Send,
  share: Share,
  terminal: Terminal,
  paperclip: Paperclip,
  play: PlayCircle,
  plus: Plus,
  upVote: ThumbsUp,
  upload: Upload,
} as const;

type IconComponents = typeof Register;
type IconPropsComponents = {
  [K in keyof IconComponents]: React.FC<React.HTMLAttributes<SVGElement>>;
};

export const Icons: IconPropsComponents = Register;
