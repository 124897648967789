import { useEffect } from "react";
import { Bird } from "lucide-react";

import { Icons } from "@/components/icons";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";

import { useGetModels } from "./hooks/use-get-models";
import { useReformatType } from "./hooks/use-reformat-type";
import { usePlaygroundStore } from "./playground-store";

export const PlaygroundSettings = () => {
  const {
    setSelectedModel,
    setRole,
    setContent,
    temperature,
    setTemperature,
    topP,
    topK,
    setTopK,
    setTopP,
  } = usePlaygroundStore();
  const { data } = useGetModels();
  const models = data?.inferenceBackends ?? [];

  useEffect(() => {
    if (!models.length) return;
    setSelectedModel(models[0]);
  }, [models]);

  if (!models.length) {
    return (
      <div className="flex items-center justify-center gap-4">
        <Bird className="h-10 text-muted-foreground" />
        <p className="text-muted-foreground">Loading models...</p>
      </div>
    );
  }

  return (
    <div
      className="relative hidden flex-col items-start gap-8 overflow-auto md:flex"
      x-chunk="dashboard-03-chunk-0"
    >
      <form className="grid w-full items-start gap-6">
        <fieldset className="grid gap-6 rounded-lg border p-4">
          <legend className="-ml-1 px-1 text-sm font-medium">Settings</legend>
          <div className="grid gap-3">
            <Label htmlFor="model">Model</Label>
            <Select defaultValue={models[0].name}>
              <SelectTrigger
                id="model"
                className="items-start [&_[data-description]]:hidden"
              >
                <SelectValue />
              </SelectTrigger>
              <SelectContent>
                {models?.map((model) => (
                  <SelectItem key={model.id} value={model.name}>
                    <div className="flex items-start gap-3 text-muted-foreground">
                      {model.icon ?? <Icons.ai className="h-5" />}

                      <div className="grid gap-0.5">
                        <p>
                          {useReformatType({ type: model.type })}
                          <span className="pl-1 font-medium text-foreground">
                            {model.name}
                          </span>
                        </p>
                        <p className="text-xs" data-description>
                          {model.description}
                        </p>
                      </div>
                    </div>
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <div className="grid gap-3">
            <Label htmlFor="temperature">Temperature</Label>
            <Input
              min={0}
              max={1}
              id="temperature"
              type="number"
              value={temperature}
              onChange={(e) => setTemperature(Number(e.target.value))}
            />
          </div>
          <div className="grid grid-cols-2 gap-4">
            <div className="grid gap-3">
              <Label htmlFor="top-p">Top P</Label>
              <Input
                id="top-p"
                type="number"
                value={topP}
                onChange={(e) => setTopP(Number(e.target.value))}
              />
            </div>
            <div className="grid gap-3">
              <Label htmlFor="top-k">Top K</Label>
              <Input
                id="top-k"
                type="number"
                value={topK}
                onChange={(e) => setTopK(Number(e.target.value))}
              />
            </div>
          </div>
        </fieldset>
        <fieldset className="grid gap-6 rounded-lg border p-4">
          <legend className="-ml-1 px-1 text-sm font-medium">Messages</legend>
          <div className="grid gap-3">
            <Label htmlFor="role">Role</Label>
            <Select defaultValue="system" onValueChange={setRole}>
              <SelectTrigger>
                <SelectValue placeholder="Select a role" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="system">System</SelectItem>
                <SelectItem value="user">User</SelectItem>
                <SelectItem value="assistant">Assistant</SelectItem>
              </SelectContent>
            </Select>
          </div>
          <div className="grid gap-3">
            <Label htmlFor="content">Content</Label>
            <Textarea
              onChange={(e) => setContent(e.target.value)}
              id="content"
              placeholder="You are a..."
              className="min-h-[9.5rem]"
            />
          </div>
        </fieldset>
      </form>
    </div>
  );
};
