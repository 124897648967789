import { createBrowserRouter, redirect, RouteObject } from "react-router-dom";

import { DashboardLayout } from "@/app/dashboard/dashboard-layout";
import ErrorPage from "@/app/error-page";
import App from "@/app/layout";

import { AuthenticationGuard } from "./app/authentication-gaurd";
import { CreateCustomSchemaPage } from "./app/dashboard/document-extract/create-custom-schema-page";
import { DocumentExtractLayout } from "./app/dashboard/document-extract/document-extract-layout";
import { DocumentExtractPage } from "./app/dashboard/document-extract/document-extract-page";
import { ResultsPage } from "./app/dashboard/document-extract/results-page";
import { UploadPage } from "./app/dashboard/document-extract/upload-page";
import { PlaygroundPage } from "./app/dashboard/playground/playground-page";
import { SettingsLayout } from "./app/dashboard/settings/settings-layout";
import { settingsRoutes } from "./app/dashboard/settings/settings-routes";
import { Login } from "./app/login/login-page";
import { Logout } from "./app/logout/logout-page";
import { Icons } from "./components/icons";
import { getDecodedJwtCookie } from "./lib/hooks/auth0/auth0";
import { loadQuery } from "./lib/hooks/graphql";
import { getUserQueryOptions } from "./lib/hooks/queries/user";

export const topLevelDashboardRoutes = [
  {
    id: "Playground",
    path: "/dashboard",
    element: <PlaygroundPage />,
    Icon: Icons.terminal,
    showInNav: true,
  },
  // {
  //   id: "Models",
  //   path: "/dashboard/models",
  //   element: <ShadCNPlaygroundPage />,
  //   Icon: Icons.bot,
  //   showInNav: true,
  // },
  // {
  //   id: "Api",
  //   path: "/dashboard/api",
  //   element: <ShadCNPlaygroundPage />,
  //   Icon: Icons.api,
  //   showInNav: true,
  // },
  {
    isExternal: true,
    id: "Documentation",
    path: "https://docs.teachprotege.ai",
    Icon: Icons.documentation,
    showInNav: true,
  },
  {
    id: "Settings",
    path: "/dashboard/settings",
    element: <SettingsLayout />,
    Icon: Icons.settings,
    children: settingsRoutes,
    showInNav: true,
  },
];

export const router = createBrowserRouter([
  {
    path: "/",
    Component: App,
    ErrorBoundary: ErrorPage,
    children: [
      {
        id: "Old Splash Route",
        path: "",
        loader: () => {
          return redirect("/dashboard");
        },
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "logout",
        element: <Logout />,
      },
      {
        id: "Dashboard",
        path: "dashboard",
        element: <AuthenticationGuard component={DashboardLayout} />,
        children: topLevelDashboardRoutes,
        loader: () => {
          const decodedJwt = getDecodedJwtCookie();
          const options = getUserQueryOptions({ id: decodedJwt?.claims.id });
          // ensure user is being fetched prior to rendering dashboard
          return loadQuery(options);
        },
      },
      {
        id: "Document Extract",
        path: "document-extract",
        element: <DocumentExtractLayout />,
        children: [
          {
            id: "Landing",
            path: "",
            element: <DocumentExtractPage />,
          },
          {
            id: "Upload",
            path: "upload",
            element: <UploadPage />,
          },
          {
            id: "Custom",
            path: "custom",
            element: <CreateCustomSchemaPage />,
          },
          {
            id: "Reults",
            path: "results",
            element: <ResultsPage />,
          },
        ],
      },
      // {
      //   id: "Invoice Parse",
      //   path: "invoice-parse",
      // element: <InvoiceParseLayout />,
      //   children: invoiceParseRoutes,
      //   // loader: () => {
      //   //   const decodedJwt = getDecodedJwtCookie();
      //   //   const options = getUserQueryOptions({ id: decodedJwt?.claims.id });
      //   //   // ensure user is being fetched prior to rendering dashboard
      //   //   return loadQuery(options);
      //   // },
      // },
    ],
  },
] satisfies RouteObject[]);
