import { GetModelBackendsQuery, InferenceBackendType } from "@/gql/graphql";

type Props = {
  type: GetModelBackendsQuery["inferenceBackends"][0]["type"];
};

export const useReformatType = ({ type }: Props) => {
  if (type === InferenceBackendType.OpenaiChat) {
    return "Chat";
  } else if (type === InferenceBackendType.OpenaiCompletions) {
    return "Completion";
  }
};
