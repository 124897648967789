import { useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { toast } from "sonner";

import { Icons } from "@/components/icons";
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/ui/spinner";

export const LogoutButton = () => {
  const { logout } = useAuth0();

  const handleLogout = useCallback(() => {
    toast("Logging out...", {
      icon: <Spinner />,
    });
    setTimeout(() => {
      logout({ logoutParams: { returnTo: window.location.origin } });
    }, 500);
  }, [logout]);

  return (
    <Button variant="ghost" size="sm" onClick={handleLogout}>
      <Icons.logout className="h-4 w-4" />
    </Button>
  );
};
