import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "@/components/ui/button";

import { useDocumentExtractStore } from "./document-extract-store";
import { EmailResultsDialog } from "./email-results-dialog";
import { ExtractionField } from "./extraction-field";

export const ResultsPage = () => {
  const navigate = useNavigate();
  const { results, uploadedFile } = useDocumentExtractStore();
  console.log({ results, uploadedFile });

  useEffect(() => {
    if (uploadedFile == null) {
      navigate("/document-extract");
    }
  }, [uploadedFile, navigate]);

  const resultsArr = Object.entries(results);

  return (
    <>
      <div className="">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto max-w-2xl text-center">
            {resultsArr.length > 0 ? (
              <>
                <h1 className="text-4xl font-bold tracking-tight sm:text-6xl">
                  Your Results
                </h1>
                <p className="mt-6 text-lg leading-8 text-primary/60">
                  What do you think? Shoot us an email @intuitivesystems and we
                  can implement this into your business today!
                </p>
                <EmailResultsDialog />
              </>
            ) : (
              <div className="col-span-2 flex flex-col items-center gap-6">
                <h1 className="text-4xl font-bold tracking-tight sm:text-6xl">
                  Uh Oh!
                </h1>
                <p className="max-w-prose text-center text-lg leading-8 text-primary/60">
                  We're really sory but we couldn't find any results. We'd love
                  to help you out if you could submit a bug report by clicking
                  the button below.
                </p>
                <Button className="">Submit Bug Report</Button>
              </div>
            )}
          </div>
          <div className="mt-12 grid grid-cols-2 gap-4 sm:mt-24">
            {/* iterate over results object and get key value */}
            {resultsArr.length > 0 &&
              resultsArr.map(([key, value], index) => (
                <ExtractionField key={index} label={key} value={value} />
              ))}
          </div>
        </div>
      </div>
    </>
  );
};
