import { Icons } from "@/components/icons";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/components/ui/select";
import { Textarea } from "@/components/ui/textarea";

type Props = {
  index?: number;
  name?: string;
  onNameChange: (name: string) => void;
  type?: string;
  onTypeChange: (type: string) => void;
  description?: string;
  onDescriptionChange: (description: string) => void;
  onRemove?: () => void;
};

export const InputField = ({
  index,
  name,
  type,
  description,
  onNameChange,
  onTypeChange,
  onDescriptionChange,
  onRemove,
}: Props) => {
  return (
    <div className="grid  grid-cols-[1fr_80px] gap-4">
      <Label className="col-span-2 flex gap-2">
        <p>
          {index != null ? `Extraction Field ${index + 1}` : "Create New Field"}
        </p>
        {index != null && (
          <Icons.delete
            onClick={onRemove}
            className="h-4 w-4 cursor-pointer hover:text-destructive"
          />
        )}
      </Label>
      <div className="col-span-2 flex gap-4">
        <Input
          value={name}
          onChange={(e) => onNameChange(e.target.value)}
          placeholder="name of field.."
        />
        <Select value={type} onValueChange={onTypeChange}>
          <SelectTrigger className="w-[90px]">
            <SelectValue />
          </SelectTrigger>
          <SelectContent>
            <SelectGroup>
              {/* <SelectLabel>Categories</SelectLabel> */}
              <SelectItem value="text">Text</SelectItem>
              <SelectItem value="number">Number</SelectItem>
              <SelectItem value="date">Date</SelectItem>
              <SelectItem value="address">Address</SelectItem>
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      <Textarea
        value={description}
        onChange={(e) => onDescriptionChange(e.target.value)}
        className="col-span-2"
        placeholder="description of field.."
      />
    </div>
  );
};
