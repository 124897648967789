import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Outlet } from "react-router-dom";

import { easeSnappy } from "@/lib/motion";

import { DashboardScreen } from "../dashboard-screen";
import { SettingsNav } from "./settings-nav";

export function SettingsLayout() {
  const [content] = useAutoAnimate({
    duration: 200,
    easing: easeSnappy,
  });

  return (
    <DashboardScreen className="grid h-display grid-cols-[auto_1fr] p-0">
      <SettingsNav />

      <main ref={content} className="h-display">
        <Outlet />
      </main>
    </DashboardScreen>
  );
}
