import { useSuspenseQuery } from "@tanstack/react-query";

import { graphql } from "@/gql/gql";
import { UserWhereUniqueInput } from "@/gql/graphql";
import { createQueryOptions } from "@/lib/hooks/graphql";
import { queryClient } from "@/queryClient";

import { useDecodedJwtCookie } from "../auth0/auth0";

const query = graphql(`
  query UserById($where: UserWhereUniqueInput!) {
    user(where: $where) {
      apiKey
      name
      id
      email
    }
  }
`);

export const getUserQueryOptions = ({ id }: UserWhereUniqueInput) => {
  return createQueryOptions({
    query,
    variables: { where: { id } },
  });
};

export const invalidateUserQuery = ({ id }: UserWhereUniqueInput) => {
  const options = getUserQueryOptions({ id });
  const queryKey = options.queryKey;

  queryClient.invalidateQueries({ queryKey });
};

export const useCurrentUserQuery = () => {
  const { decodedJwt } = useDecodedJwtCookie();
  const id = decodedJwt?.claims?.id;
  const options = getUserQueryOptions({ id });

  return useSuspenseQuery(options);
};

export const useUserQuery = ({ id }: UserWhereUniqueInput) => {
  const options = getUserQueryOptions({ id });

  return useSuspenseQuery(options);
};
